import type * as pt from "@prismicio/types"
import { CmsGuideLayout } from "../../../components/CmsGuideLayout"
import { lorem } from "../../../lib/lorem"
import { PageDataBodyTextHeading } from "../../../slices/PageDataBodyTextHeading"

const Component = () => {
	return (
		<CmsGuideLayout>
			<PageDataBodyTextHeading
				header={lorem.words(5)}
				centerText
				text={
					[
						{ type: "paragraph", text: lorem.paragraphs(1), spans: [] },
						{ type: "paragraph", text: lorem.paragraphs(1), spans: [] },
					] as pt.RichTextField
				}
			/>
		</CmsGuideLayout>
	)
}

export default Component
